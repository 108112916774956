const lngLatFormatter = (e, prefix = true) => {
  let res = ''
  if (prefix) {
    res = `GPS: `
  }
  res += `${e.lngLat.lat.toFixed(5)}, ${e.lngLat.lng.toFixed(5)}`
  return res
}

const getFormatter = fmt => {
  if (fmt) {
    return fmt
  } else {
    return lngLatFormatter
  }
}

const showTooltip = ({ map, element, formatter }) => {
  const fmt = getFormatter(formatter)

  map.on('mousemove', e => {
    document.getElementById(element).innerHTML = fmt(e)
  })
}
export { showTooltip, lngLatFormatter }
