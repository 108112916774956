<template>
  <div>
    <v-menu
      v-model="menu"
      bottom
      right
      transition="scale-transition"
      origin="top left"
      :close-on-content-click="false"
    >
      <template v-slot:activator="{ on }">
        <span v-on="on">
          <water-source-chip :source="data.water_source"></water-source-chip>
        </span>
      </template>
      <v-card min-width="400" max-width="600">
        <v-card-title>
          Drinking Water Information
          <v-spacer></v-spacer>
          <v-btn
            icon
            color="primary"
            :to="`/parcels/${parcelId}/water?source=true`"
            ><v-icon>mdi-pencil</v-icon></v-btn
          >
          <v-btn @click="menu = false" icon><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-container class="system-information">
          <p v-if="data.water_source">Water Source: {{ data.water_source }}</p>
          <p v-if="data.installed && Object.keys(data.installed).length">
            <span>Installed</span><br />
            <span class="ml-5" v-if="data.installed.by">
              By: {{ data.installed.by }} </span
            ><br />
            <span class="ml-5" v-if="data.installed.year">
              Year: {{ data.installed.year }}
            </span>
          </p>
          <p v-if="data.well_location || data.well_location_sketch.exists">
            <span>Well Location</span><br />
            <span class="ml-5" v-if="data.well_location">
              Well Location: {{ data.well_location }} </span
            ><br />
            <span class="ml-5" v-if="data.well_location_sketch.exists">
              Sketch:
              <a :href="data.well_location_sketch.sketch.url" target="_blank">{{
                data.well_location_sketch.sketch.filename
              }}</a>
            </span>
          </p>
        </v-container>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
export default {
  props: ['data', 'parcelId'],
  data: () => ({
    menu: false
  })
}
</script>

<style scoped>
.system-information {
  font-size: 1rem;
}
p {
  margin-top: -0.4rem;
}
</style>
