<template>
  <div
    :class="{ 'text-center': center, 'sticky-form-action': sticky, pinned }"
    class="b-form-action"
  >
    <slot name="submit">
      <v-btn
        v-show="showSubmit && !showClose"
        class="text-capitalize"
        :x-large="xLarge"
        v-bind="submitAttrs"
        :loading="loading"
        :disabled="disabled"
        @click="onSubmit"
      >
        {{ submitLabelComputed }}
        <v-icon v-if="submitIcon">
          {{ submitIcon }}
        </v-icon>
      </v-btn>
    </slot>
    <slot name="cancel">
      <v-btn
        v-show="showCancel && !showClose"
        class="text-capitalize"
        text
        color="grey"
        :x-large="xLarge"
        v-bind="cancelAttrs"
        @click="onCancel"
      >
        {{ cancelLabel }}
        <v-icon v-if="cancelIcon">
          {{ cancelIcon }}
        </v-icon>
      </v-btn>
    </slot>
    <slot name="close">
      <v-btn
        v-show="showClose"
        class="text-capitalize"
        color="success"
        outlined
        :x-large="xLarge"
        v-bind="cancelAttrs"
        @click="onClose"
      >
        Close <v-icon>mdi-close</v-icon>
      </v-btn>
    </slot>
  </div>
</template>
<script>
export default {
  props: {
    xLarge: {
      type: Boolean,
      default: true,
    },
    center: {
      type: Boolean,
      default: true,
    },
    submitLabel: {
      type: String,
      default: 'Submit',
    },
    submitIcon: {
      type: String,
      default: '',
    },
    cancelLabel: {
      type: String,
      default: 'Cancel',
    },
    cancelIcon: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    save: {
      type: Boolean,
      default: false,
    },
    update: {
      type: Boolean,
      default: false,
    },
    submitAttrs: {
      type: Object,
      default: () => ({ color: 'primary' }),
    },
    cancelAttrs: { type: Object },
    vm: { type: Object },
    disabled: {
      type: Boolean,
      default: false,
    },
    showSubmit: {
      type: Boolean,
      default: true,
    },
    showCancel: {
      type: Boolean,
      default: true,
    },
    sticky: {
      type: Boolean,
      default: false,
    },
    showClose: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      pinned: false,
    };
  },
  computed: {
    submitLabelComputed() {
      if (this.save) {
        return 'Save';
      }
      if (this.update) {
        return 'Update';
      }
      return this.submitLabel;
    },
  },
  mounted() {
    setTimeout(() => {
      try {
        const stickyElm = document.querySelector('.b-form-action');
        const observer = new IntersectionObserver(
          ([e]) => {
            e.target.classList.toggle('pinned', e.intersectionRatio < 1);
            this.pinned = e.intersectionRatio < 1;
          },
          {
            rootMargin: '0px 0px -1px 0px',
            threshold: [1],
          },
        );
        observer.observe(stickyElm);
      } catch {
        //
      }
    }, 100);
  },
  methods: {
    onSubmit() {
      if (this.vm) {
        this.vm.onSubmit();
      }
      this.$emit('click');
    },
    onCancel() {
      if (this.vm) {
        this.vm.onCancel();
      }
      this.$emit('cancel');
    },
    onClose() {
      this.$emit('close');
    },
  },
};
</script>

<style scoped lang="scss">
.sticky-form-action {
  position: -webkit-sticky;
  position: sticky;
  bottom: -1px;
  margin-left: -12px;
  margin-right: -12px;
  padding: 10px;
}
.pinned {
  background-color: rgba(255, 255, 255, 0.562);
}
</style>
