<template>
  <div>
    <v-dialog v-model="dialog" max-width="800">
      <v-card class="mx-auto">
        <v-card-title
          >Edit Parcel <v-spacer></v-spacer
          ><v-btn icon @click="toggleDialog()"
            ><v-icon>mdi-close</v-icon></v-btn
          ></v-card-title
        >
        <v-container>
          <base-parcel-form
            :showOpt="true"
            :clearForm="false"
            :data="parcelData"
            @submit="update"
            @cancel="dialog = false"
          >
            <template v-slot:button
              >Update <v-icon>mdi-content-save</v-icon></template
            >
          </base-parcel-form>
        </v-container>
      </v-card>
    </v-dialog>
    <v-btn icon color="primary" @click="toggleDialog()"
      ><v-icon>{{ icon }}</v-icon></v-btn
    >
  </div>
</template>

<script>
import BaseParcelForm from '@/components/Parcel/BaseForm'
import parcel from '@/mixins/parcel'
import audio from '@/mixins/audio'
export default {
  mixins: [parcel, audio],
  props: {
    data: {},
    icon: {
      type: String,
      default: 'mdi-pencil'
    }
  },
  components: {
    BaseParcelForm
  },
  data: () => ({
    parcelData: {},
    dialog: false
  }),
  methods: {
    setParcelData() {
      this.parcelData = { ...this.data }
    },
    toggleDialog() {
      this.dialog = !this.dialog
    },
    async update(data) {
      this.loadingAddParcel = true
      let parcelData = { ...data }
      parcelData.number = this.addLeadingZero(parcelData.number)

      let parcelID = parcelData.id
      delete parcelData.id
      await this.updateParcel(parcelID, parcelData)
      this.toggleDialog()
      this.playFrog()

      this.$emit('update', { ...parcelData, id: parcelID })
      this.updateMetrics({
        feature: 'parcel',
        action: 'update',
        parcel: parcelID,
        number: parcelData.number
      })
      this.loadingAddParcel = false
    }
  },
  watch: {
    data: {
      immediate: true,
      handler: 'setParcelData'
    }
  }
}
</script>

<style></style>
