<template>
  <v-btn
    v-bind="$attrs"
    :href="getPropertyCard(data.key).url"
    target="_blank"
    class="text-none mt-2 mr-2"
    color="info"
    v-if="data.key"
    :width="autoWidth ? '' : width"
    small
    :text="textBtn"
  >
    <v-icon>mdi-folder-key</v-icon>
    {{ text }}
    <slot name="cardKey" :cardKey="data.key">
      {{ data.key }}
    </slot>
  </v-btn>
</template>

<script>
import parcel from '@/mixins/parcel'
export default {
  inheritAttrs: false,
  mixins: [parcel],
  // props: ["data"],
  props: {
    data: {},
    width: {
      type: Number,
      default: 170
    },
    text: {
      type: String,
      default: ''
    },
    textBtn: {
      type: Boolean,
      default: false
    },
    autoWidth: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style></style>
