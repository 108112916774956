<template>
  <div>
    <v-form>
      <div>
        <v-textarea
          v-model="description"
          label="Description"
          rows="1"
          auto-grow
        />
      </div>
      <div>
        <Filepond
          :user="user"
          :folder-id="folderId"
          :total.sync="total"
          :uploaded.sync="uploaded"
          :upload-errors.sync="uploadErrors"
          @upload="onUpload"
        />
      </div>
      <div class="text-center py-3">
        <FormAction
          submit-label="Close"
          :show-submit="!total || total === uploaded"
          :show-cancel="total !== 0 && total !== uploaded"
          :submit-attrs="{ color: 'success' }"
          @click="onSubmit"
          @cancel="onCancel"
        />
      </div>
    </v-form>
  </div>
</template>

<script>
import Filepond from './Filepond.vue';
import FormAction from './FormAction.vue';

import Drive from './drive';

const drive = new Drive();

export default {
  components: {
    Filepond,
    FormAction,
  },
  props: {
    user: {
      type: String,
      required: true,
    },
    folderId: {
      type: [String, null],
      default: null,
    },
  },
  data() {
    return {
      description: '',
      total: 0,
      uploaded: 0,
      uploadErrors: 0,
    };
  },
  computed: {
    uploading() {
      if (this.total) {
        if (this.total === this.uploaded) {
          return false;
        }
        return true;
      }
      return false;
    },
  },
  watch: {
    uploading: {
      handler(value) {
        this.$emit('update:uploading', value);
      },
      immediate: true,
    },
  },
  methods: {
    onSubmit() {
      this.$emit('close');
    },
    onCancel() {
      this.$emit('close');
    },
    onUpload(fileInfo) {
      this.$emit('update:uploading', true);
      drive.createFile({
        ...fileInfo,
        folderId: this.folderId,
        user: this.user,
        description: this.description,
      })
        .then(() => {
          if (this.total !== 0) {
            if (this.total === this.uploaded) {
              this.$emit('upload');
            }
          }
        });
    },
  },
};
</script>

<style>
</style>
