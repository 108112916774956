<template>
  <div>
    <span><slot name="label"> {{ label }}</slot></span>
    <slot :text="text">
      <span id="target">
        <slot
          name="text"
          :text="text"
        >
          {{ text }}
        </slot>
      </span>
      <slot name="after-text" />
    </slot>
    <div class="btn-container d-inline">
      <slot
        name="activator"
        :v-bind="on"
      >
        <v-btn
          id="activator"
          v-clipboard:copy="text"
          v-clipboard:success="onCopy"
          v-clipboard:error="onError"
          text
          :color="color"
          v-bind="btnAttrs"
        >
          {{ btnText }} <v-icon>{{ icon }}</v-icon>
        </v-btn>
      </slot>
      <v-fade-transition>
        <div
          v-if="copied"
          class="text-center"
        >
          <span class="success--text">{{ successMessage }}</span>
        </div>
      </v-fade-transition>
      <v-fade-transition>
        <div
          v-if="error"
          class="text-center"
        >
          <span class="success--text">{{
            $store.state.ERROR_MESSAGES.default
          }}</span>
        </div>
      </v-fade-transition>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import VueClipboard from 'vue-clipboard2';

VueClipboard.config.autoSetContainer = true;

Vue.use(VueClipboard);
export default {
  props: {
    icon: {
      type: String,
      default: 'mdi-clipboard-text-multiple-outline',
    },
    label: {
      type: String,
      default: '',
    },
    btnText: {
      type: String,
      default: 'Copy',
    },
    text: {
      type: String,
      default: '',
    },
    btnAttrs: {
      type: Object,
    },
    successMessage: {
      type: String,
      default: 'Copied to clipboard',
    },
    color: {
      type: String,
      default: 'primary',
    },
  },
  data() {
    return {
      copied: false,
      error: false,
    };
  },
  methods: {
    on() {
      //
    },
    onCopy() {
      this.$emit('copy', { message: this.successMessage, type: 'success' });
    },
    onError(error) {
      this.$emit('copy', { message: error, type: 'error' });
    },
  },
};
</script>
