<template>
  <v-card class="mx-auto">
    <v-card-title>
      New Folder <v-spacer />
      <v-btn
        icon
        @click="$emit('cancel')"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-container>
      <CreateFolderForm
        :loading="loading"
        @submit="onCreateFolder"
        @cancel="$emit('close')"
      >
        <template #messages>
          <v-alert
            v-if="messages.createFolder.error"
            type="error"
          >
            {{ messages.createFolder.error }}
          </v-alert>
        </template>
      </CreateFolderForm>
    </v-container>
  </v-card>
</template>

<script>
import CreateFolderForm from './CreateFolderForm.vue';
import Drive from './drive';

const drive = new Drive();

export default {
  components: { CreateFolderForm },

  props: {
    user: {
      type: String,
      required: true,
    },
    parentId: {
      type: [String, null],
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      messages: {
        createFolder: {
          error: '',
          success: '',
        },
      },
    };
  },

  methods: {
    async onCreateFolder(data) {
      this.loading = true;
      const { name } = data;
      try {
        await drive.createFolder({
          name,
          user: this.user,
          parentId: this.parentId,
        });
        this.$emit('success', this.parentId);
        this.$emit('close');
      } catch (error) {
        this.messages.createFolder.error = error.message;
      }
      this.loading = false;
    },
  },
};
</script>

<style>
</style>
