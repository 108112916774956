<template>
  <v-card class="mx-auto">
    <v-card-text>
      <div class="pt-3">
        <slot name="head" />
      </div>
      <div>
        <slot>
          <h3 :class="titleClass">
            {{ confirmMessage }}
          </h3>
        </slot>
      </div>
      <div>
        <AlertMessage
          :error="error"
          :success="success"
        />
      </div>
      <div class="text-center mt-4">
        <v-btn
          :small="small"
          :color="confirmColor"
          v-bind="confirmAttrs || {}"
          class="mr-1"
          @click="$emit('confirm')"
        >
          {{ confirmLabel }}
          <v-icon v-if="confirmIcon">
            {{ confirmIcon }}
          </v-icon>
        </v-btn>
        <v-btn
          :small="small"
          :color="cancelColor"
          dark
          v-bind="cancelAttrs || {}"
          @click="onCancel"
        >
          {{ cancelLabel }}
          <v-icon v-if="cancelIcon">
            {{ cancelIcon }}
          </v-icon>
        </v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import AlertMessage from './AlertMessage.vue';

export default {
  components: {
    AlertMessage,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    confirmLabel: {
      type: String,
      default: 'Yes',
    },
    confirmMessage: {
      type: String,
      default: 'Are you sure?',
    },
    cancelLabel: {
      type: String,
      default: 'No',
    },
    confirmIcon: {
      type: String,
      default: 'mdi-trash-can',
    },
    cancelIcon: {
      type: String,
      default: 'mdi-close',
    },
    confirmColor: {
      type: String,
      default: 'error',
    },
    cancelColor: {
      type: String,
      default: 'grey',
    },
    confirmAttrs: {
      type: Object,
      default: () => ({}),
    },
    error: {
      type: String,
      default: '',
    },
    success: {
      type: String,
      default: '',
    },
    cancelAttrs: {
      type: Object,
      default: () => ({}),
    },
    small: {
      type: Boolean,
      default: false,
    },
    titleClass: {
      type: String,
      default: 'text-h5',
    },
  },
  methods: {
    onCancel() {
      this.$emit('input', false);
      this.$emit('cancel');
    },
  },
};
</script>

<style>
</style>
