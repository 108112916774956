<template>
  <div>
    <v-tooltip
      :color="value ? 'primary' : 'grey'"
      bottom
      :disabled="$vuetify.breakpoint.xs"
    >
      <template #activator="{ on }">
        <div v-on="on">
          <v-badge overlap :content="count" v-if="count">
            <v-btn
              :class="value ? 'active' : ''"
              :fab="!icon"
              :icon="icon"
              small
              @click="$emit('input', !value)"
              :color="value ? 'primary' : 'grey'"
              :dark="!value"
              ><v-icon>
                {{ iconComputed }}
              </v-icon></v-btn
            >
          </v-badge>
          <v-btn
            v-else
            :class="value ? 'active' : ''"
            :fab="!icon"
            :icon="icon"
            small
            @click="$emit('input', !value)"
            :color="value ? 'primary' : 'grey'"
            :dark="!value"
            ><v-icon>
              {{ iconComputed }}
            </v-icon></v-btn
          >
        </div>
      </template>
      <span>Drive</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      required: true
    },
    icon: {
      type: Boolean,
      default: false
    },
    count: {
      type: Number,
      default: 0
    }
  },
  computed: {
    iconComputed() {
      return 'mdi-cloud'
    }
  }
}
</script>

<style scoped lang="scss">
// .active {
//   transform: rotate(45deg);
// }
</style>
