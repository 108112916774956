<template>
  <div>
    <viewer
      hidden
      :options="options"
      :images="image"
      @inited="inited"
      class="viewer"
      ref="viewer"
    >
      <template slot-scope="scope">
        <img v-for="src in scope.images" :src="src" :key="src" />
        {{ scope.options }}
      </template>
    </viewer>
    <v-btn
      :color="color"
      :text="text"
      :small="resize ? $vuetify.breakpoint.lgAndUp : false"
      class="mt-2 text-capitalize v-btn--has-bg"
      @click="showSampleBill()"
      >Real Estate Tax Bill
      <v-icon :small="$vuetify.breakpoint.lgAndUp"
        >mdi-information</v-icon
      ></v-btn
    >
  </div>
</template>

<script>
const sampleBill = require('@/assets/img/TruroTaxBill.png')
export default {
  props: {
    color: {
      type: String,
      default: 'success'
    },
    text: {
      type: Boolean,
      default: false
    },
    resize: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    dialog: false,
    image: [sampleBill],
    options: {
      navbar: false,
      title: false,
      toolbar: false
    }
  }),
  methods: {
    showSampleBill() {
      this.$viewer.show()
    },
    inited(viewer) {
      this.$viewer = viewer
    }
  }
}
</script>

<style></style>
