<template>
  <div id="mapCoordinates"></div>
</template>

<script>
import { showTooltip } from '../../utils/map'
export default {
  mounted() {
    const map = this.$parent.map
    const element = 'mapCoordinates'
    showTooltip({ map, element })
  }
}
</script>

<style lang="sass">
#mapCoordinates
  position: absolute
  left: 0
  bottom: 0
  right: 0
  margin-left: auto
  margin-right: auto
  width: 220px
  background-color: white
  text-align: center
  border-top-right-radius: 3px
  border-top-left-radius: 3px
</style>