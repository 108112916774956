<template>
  <v-card>
    <v-card-title>
      Upload File <v-spacer />
      <v-btn
        v-show="!uploading"
        icon
        @click="$emit('close')"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-container>
      <upload-file-form
        :uploading.sync="uploading"
        :folder-id="parentId"
        :user="user"
        @upload="$emit('upload')"
        @close="$emit('input', false)"
      />
    </v-container>
    <div class="text-center py-3" />
  </v-card>
</template>

<script>
import UploadFileForm from './UploadFileForm.vue';

export default {
  components: { UploadFileForm },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    user: {
      type: String,
      required: true,
    },
    parentId: {
      type: [String, null],
      default: null,
    },
  },
  data() {
    return {
      uploading: false,
    };
  },
};
</script>

<style>
</style>
