<template>
  <div>
    <v-scroll-y-transition>
      <v-alert
        v-if="success"
        :dense="dense"
        type="success"
        dismissible
      >
        {{
          success
        }}
      </v-alert>
    </v-scroll-y-transition>
    <v-scroll-y-transition>
      <v-alert
        v-if="error"
        :dense="dense"
        type="error"
        dismissible
      >
        {{
          error
        }}
      </v-alert>
    </v-scroll-y-transition>
    <v-scroll-y-transition>
      <v-alert
        v-if="info"
        :dense="dense"
        type="info"
        dismissible
      >
        {{
          info
        }}
      </v-alert>
    </v-scroll-y-transition>
  </div>
</template>

<script>
export default {
  props: {
    success: {
      type: String,
      default: '',
    },
    error: {
      type: String,
      default: '',
    },
    info: {
      type: String,
      default: '',
    },
    dense: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

      <style>
</style>
