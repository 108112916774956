import path from 'path';
import { randomBytes } from 'crypto';

function extension(filename) {
  return String(filename.split('.').pop()).toLowerCase();
}

function getExtension(name) {
  return name.split('.').pop() || '';
}

function addExtension(name, ext) {
  return `${name}.${ext}`;
}

function removeExtension(name) {
  return String(name).substr(0, String(name).lastIndexOf('.'));
}

function updateExtension(name, ext) {
  return addExtension(removeExtension(name), ext);
}

function formatPath(str) {
  return str
    .split('/')
    .filter((x) => x)
    .join('/');
}

const getFilePath = (folders, filename) => path.join(...folders, filename);

const indexOf = (files, id) => files.findIndex((item) => item.id === id);

const updateObject = (data, payload) => {
  const res = { ...data };
  Object.keys(payload).forEach((key) => {
    res[key] = payload[key];
  });
  return res;
};

const validateObj = (obj) => {
  const res = { ...obj };
  Object.keys(res).forEach((key) => {
    if (res[key] === undefined) {
      res[key] = null;
    }
  });
  return res;
};

const randomString = (len = 32, str = 'hex') => randomBytes(len).toString(str);

const downloadBlob = (blob, filename) => {
  if (window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(blob, filename);
  } else {
    const a = document.createElement('a');
    document.body.appendChild(a);
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = filename;
    a.click();
    setTimeout(() => {
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    }, 0);
  }
};

const downloadURI = (uri, name) => {
  const xhr = new XMLHttpRequest();
  xhr.responseType = 'blob';
  xhr.onload = () => {
    const blob = xhr.response;
    downloadBlob(blob, name);
  };
  xhr.open('GET', uri);
  xhr.send();
};

export {
  getExtension,
  extension,
  addExtension,
  removeExtension,
  updateExtension,
  formatPath,
  getFilePath,
  indexOf,
  updateObject,
  validateObj,
  randomString,
  downloadURI,
  downloadBlob,
};
