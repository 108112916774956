<template>
  <div>
    <v-menu
      v-model="menu"
      bottom
      right
      transition="scale-transition"
      origin="top left"
      :close-on-content-click="false"
    >
      <template v-slot:activator="{ on }">
        <span v-on="on">
          <septic-system-chip
            :componentType="data.component_type"
          ></septic-system-chip>
        </span>
      </template>
      <v-card min-width="400" max-width="600">
        <v-card-title>
          System Information
          <v-spacer></v-spacer>
          <v-btn
            icon
            color="primary"
            :to="`/parcels/${parcelId}/septic?septic_card=true`"
            ><v-icon>mdi-pencil</v-icon></v-btn
          >
          <v-btn @click="menu = false" icon><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-container class="system-information">
          <p v-if="data.component_type">
            Component Type: {{ getSepticComponent(data.component_type) }}
          </p>
          <p v-if="data.installed && Object.keys(data.installed).length">
            <span>Installed</span><br />
            <span class="ml-5" v-if="data.installed.by">
              By: {{ data.installed.by }} </span
            ><br />
            <span class="ml-5" v-if="data.installed.year">
              Year: {{ data.installed.year }}
            </span>
          </p>
          <p v-if="data.pumped && Object.keys(data.pumped).length">
            <span>Pumped</span><br />
            <span class="ml-5" v-if="data.pumped.by">
              By: {{ data.pumped.by }} </span
            ><br />
            <span class="ml-5" v-if="data.pumped.year">
              Year: {{ data.pumped.year }}
            </span>
          </p>
          <p
            v-if="
              data.cleanout_location_description ||
                (data.cleanout_location && data.cleanout_location.sketch)
            "
          >
            <span> Cleanout Location </span><br />
            <span class="ml-5" v-if="data.cleanout_location_description">
              Description:
              {{ data.cleanout_location_description }}
            </span>
            <br />
            <span class="ml-5" v-if="data.cleanout_location.sketch.exists">
              Sketch:
              <a :href="data.cleanout_location.sketch.url" target="_blank">{{
                data.cleanout_location.sketch.filename
              }}</a>
            </span>
          </p>
        </v-container>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import septic from '@/mixins/septic'
export default {
  mixins: [septic],
  props: ['data', 'parcelId'],
  data: () => ({
    menu: false
  })
}
</script>

<style scoped>
.system-information {
  font-size: 1rem;
}
p {
  margin-top: -0.4rem;
}
</style>
