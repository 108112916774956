<template>
  <div class="parcel-detail">
    <v-dialog
      v-model="dialogs.parcelMap"
      :max-width="mapDialogSize == 'ls' ? '100%' : '1000'"
    >
      <v-card class="mx-auto">
        <v-card-title class="map-card--title">
          <span v-if="$vuetify.breakpoint.mdAndUp">Parcel ID: </span>
          <span class="ml-1 map-parcel--text">
            {{ removePadding(data.number) }}
          </span>
          <v-spacer v-if="getStreetAddress(data)"></v-spacer>
          <span v-if="getStreetAddress(data)">
            {{ getStreetAddress(data) }}
          </span>
          <v-spacer v-if="data.acres" class="d-none d-sm-flex"></v-spacer>
          <span class="d-none d-sm-flex" v-if="data.acres">
            {{ data.acres }} Acres
          </span>
          <v-spacer></v-spacer>
          <v-menu
            :close-on-content-click="false"
            left
            transition="slide-y-transition"
            v-model="dialogs.stat"
          >
            <template v-slot:activator="{ on }">
              <v-btn style="margin-left: 30px" v-on="on" color="info" icon>
                <v-icon>mdi-information</v-icon>
              </v-btn>
            </template>
            <v-card max-width="400" class="mx-auto">
              <v-card-title>
                <v-spacer></v-spacer>
                <v-btn @click="dialogs.stat = false" icon
                  ><v-icon>mdi-close</v-icon></v-btn
                >
              </v-card-title>
              <v-container>
                <v-simple-table fixed-header>
                  <template v-slot:default>
                    <tbody>
                      <tr class="d-sm-none">
                        <th>Acres</th>
                        <td>
                          {{ data.acres }}
                        </td>
                      </tr>
                      <tr
                        v-for="(data, index) in computedParcelData"
                        :key="index"
                      >
                        <th>{{ data.name }}</th>
                        <td>{{ data.description }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-container>
            </v-card>
          </v-menu>

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <span v-on="on" style="margin-top: -7px !important">
                <property-card-key :data="data" textBtn witdh="auto" autoWidth>
                  <template v-slot:cardKey="{ cardKey }">
                    <span class="d-none d-sm-inline">
                      {{ cardKey }}
                    </span>
                  </template>
                </property-card-key>
              </span>
            </template>
            <span>Prop Card {{ data.key }}</span>
          </v-tooltip>

          <v-icon
            @click="
              $router.push(
                `/parcels?view=map&ignoreHash=true&bbox=${JSON.stringify(
                  data.bbox
                )}#15/${data.center[1]}/${data.center[0]}`
              )
            "
            >mdi-map</v-icon
          >
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                icon
                @click="changeMapDialogSize()"
                v-if="$vuetify.breakpoint.lgAndUp"
              >
                <v-icon>{{
                  mapDialogSize == 'ls'
                    ? 'mdi-fullscreen-exit'
                    : 'mdi-fullscreen'
                }}</v-icon>
              </v-btn>
            </template>
            <span>
              {{ mapDialogSize == 'ls' ? 'Reduce Screen' : 'Expand Screen' }}
            </span>
          </v-tooltip>

          <v-menu>
            <template #activator="{ on }">
              <v-icon v-on="on">mdi-cog</v-icon>
            </template>
            <map-settings :show="['style', 'scale']" />
          </v-menu>
          <v-btn @click="dialogs.parcelMap = false" icon
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <mgl-map
          :key="mapKey"
          ref="map"
          @load="
            onMapLoad({ map: $event, hoverEffect: true, showTooltip: true })
          "
          :center="data.center"
          :mapStyle="mapStyle"
          :zoom="16"
          style="width: 100%; height: 85vh"
        >
          <mgl-marker :coordinates="data.center"></mgl-marker>
          <mgl-scale-control
            v-if="scaleFilter"
            position="bottom-right"
          ></mgl-scale-control>
          <map-coordinates />
        </mgl-map>
      </v-card>
    </v-dialog>
    <v-card outlined>
      <v-card-text>
        <peeps-alert-animation
          ref="animation"
          message="Parcel update was Successful!"
        ></peeps-alert-animation>
        <div class="d-flex justify-space-between">
          <h2 style="font-size: 1.1em" class="map-parcel--text mt-2">
            {{ removePadding(data.number) }}
          </h2>
          <v-spacer></v-spacer>
          <edit-parcel :data="data" @update="emitUpdatedData"></edit-parcel>
          <delete-parcel
            :goBack="false"
            @update="$emit('close')"
            :id="data.id"
          ></delete-parcel>
          <v-btn v-if="showClose" icon @click="$emit('close')"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </div>
        <div>
          <span class="font-weight-bold">
            {{ data.street_number }} {{ data.street_name }} </span
          ><br />
          <span v-if="data.townCity || data.state">
            {{ data.townCity }}, {{ data.state }}, {{ data.zip }}
          </span>
        </div>

        <p class="mt-3">
          <v-chip v-if="data.parcel_type" color="info" small>{{
            getParcelTypeText(data.parcel_type)
              ? getParcelTypeText(data.parcel_type)
              : 'Loading'
          }}</v-chip>
          <v-chip class="ml-1 mr-1" v-if="data.waterfront" color="success" small
            >Waterfront</v-chip
          >
          <v-chip class="ml-1 mr-1" v-if="data.waterview" color="success" small
            >Waterview</v-chip
          >
        </p>
        <div>
          <v-row class="mt-5">
            <v-col cols="6" class="mt-n5">
              Acres: {{ data.acres || '' }}
            </v-col>
            <v-col cols="6" class="mt-n5" v-if="showHouseLabel">
              House: {{ data.house_area ? `${data.house_area} SF` : '' }}
            </v-col>
            <v-col cols="6" class="mt-n5" v-if="showBedBathLabel">
              Bedrooms: {{ data.bedroom || '' }}
            </v-col>
            <v-col cols="6" class="mt-n5" v-if="showBedBathLabel">
              Bathrooms: {{ data.bathroom || '' }}
            </v-col>
            <v-col cols="6" class="mt-n5">
              Class: {{ data.state_class || '' }}
            </v-col>
            <v-col cols="6" class="mt-n5" v-if="profile.includeParcelReference">
              Ref: {{ data.reference }}
            </v-col>
          </v-row>
        </div>
        <v-expand-transition>
          <div v-show="gallery" class="mb-1">
            <div class="outlined pa-1">
              <ParcelGalleryDetail
                :parcel="data.number"
                :count.sync="galleryCount"
              />
            </div>
          </div>
        </v-expand-transition>
        <v-expand-transition>
          <div v-show="drive">
            <div class="outlined pa-1">
              <Drive
                center-buttons
                small-buttons
                :user="$store.state.user.uid"
                :folder-id="data.id"
                hide-copy-id
                :count.sync="driveCount"
              />
            </div>
          </div>
        </v-expand-transition>
        <div class="outlined pb-1 mt-1" :class="drive || gallery ? 'mt-3' : ''">
          <div style="position: relative">
            <div class="parcel-gb d-flex">
              <DriveButtonToggle
                v-model="drive"
                class="mr-2"
                :count="driveCount"
              />
              <ParcelGalleryButton v-model="gallery" :count="galleryCount" />
            </div>
          </div>
          <div
            class="feature-group mt-3 ml-3"
            v-for="(f, index) in featureButtons"
            :key="index"
          >
            <div v-show="isFeatureAvailable(f.feature)">
              <div class="d-flex flex-wrap">
                <div @click="latestParcel(data)">
                  <v-badge
                    :color="colors['light-black']"
                    v-if="featureCount(f.feature)"
                    :content="featureCount(f.feature)"
                    overlap
                  >
                    <feature-button
                      :color="colors['light-black']"
                      :to="featureLink(f.url)"
                      v-model="f.feature"
                      outlined
                    ></feature-button>
                  </v-badge>
                  <feature-button
                    useIconColor
                    :color="colors['light-black']"
                    v-else
                    :to="featureLink(f.url)"
                    outlined
                    v-model="f.feature"
                  ></feature-button>
                </div>
                <div>
                  <div>
                    <v-menu v-if="f.about" open-on-hover offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="info"
                          class="info-icon"
                          dark
                          icon
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon>mdi-information-outline</v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item>
                          <v-list-item-title
                            >{{ f.about.text }}
                            <router-link :to="f.about.url">{{
                              f.about.value
                            }}</router-link></v-list-item-title
                          >
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </div>
                </div>
                <septic-system-information
                  class="mt-2"
                  v-if="getInformation(f.feature) && f.feature == 'septic'"
                  :data="getInformation(f.feature)"
                  :parcelId="data.id"
                >
                </septic-system-information>
                <water-source-information
                  class="mt-2"
                  v-if="f.feature == 'water' && getInformation(f.feature)"
                  :data="getInformation(f.feature)"
                  :parcelId="data.id"
                ></water-source-information>
                <hvac-information
                  :value="getInformation(f.feature)"
                  class="mt-2"
                  v-if="f.feature == 'hvac' && getInformation(f.feature)"
                  :parcelId="data.id"
                ></hvac-information>
              </div>
            </div>
          </div>
        </div>
        <v-sheet
          class="pa-2 mt-3 map-container"
          v-if="data.center"
          @click.prevent="dialogs.parcelMap = true"
        >
          <mgl-map
            :key="mapKey"
            class="map-clickable"
            @load="onMapLoad({ map: $event, thumbnail: true })"
            style="height: 200px; width: 100%"
            :center="data.center"
            :mapStyle="mapStyle"
            :zoom="15"
          >
            <mgl-marker :coordinates="data.center"></mgl-marker>
          </mgl-map>
          <div class="text-center">
            <span class="map-hint">Click to see larger map</span>
          </div>
        </v-sheet>
        <div class="outlined mt-3 pa-2">
          <property-card-key
            :data="data"
            text="Card Key"
            v-if="data.key || stateTown('MA', 'Truro')"
          >
          </property-card-key>
          <v-btn
            width="170"
            v-if="stateTown('MA', 'Truro')"
            class="text-none mt-2 mr-2"
            color="info"
            @click="showAccessorData"
            small
            dark
          >
            <v-icon>mdi-database</v-icon>
            Accessor Data
          </v-btn>
          <v-btn
            width="170"
            v-if="stateTown('MA', 'Truro')"
            class="text-none mt-2 mr-2"
            color="info"
            small
            dark
            @click="showAccessorMap"
          >
            <v-icon>mdi-map-marker</v-icon>
            Accessor Map
          </v-btn>
          <v-menu v-model="dialogs.snlMenu">
            <template v-slot:activator="{ on }">
              <v-btn
                v-show="showNitrogenFootprint"
                v-on="on"
                width="170"
                class="text-none mt-2 mr-2"
                color="info"
                small
                dark
              >
                Nitrogen Footprint
              </v-btn>
            </template>
            <v-card max-width="400">
              <v-card-title
                :style="`background-color:${getNitrateColor(
                  calculateSnl(data)
                )}`"
              >
                Simple Nitrogen Load
                <v-spacer></v-spacer>
                <v-btn @click="dialogs.snlMenu = false" icon>
                  <v-icon> mdi-close </v-icon>
                </v-btn>
              </v-card-title>
              <v-card-text>
                <div class="text-center">
                  <h3 class="text-h6">{{ calculateSnl(data) }}</h3>
                </div>
              </v-card-text>
            </v-card>
          </v-menu>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import DeleteParcel from '@/components/Parcel/DeleteParcel'
import EditParcel from '@/components/Parcel/EditParcel'
import SepticSystemInformation from '@/components/Septic/SystemInformation'
import PropertyCardKey from './PropertyCardKey.vue'
import WaterSourceInformation from '@/components/Water/SourceInformation'
import HvacInformation from '@/components/HVAC/HVACInformation'

import ParcelGalleryButton from '@/components/Gallery/ParcelGalleryButton'
import ParcelGalleryDetail from '@/components/Gallery/ParcelGalleryDetail'

import parcel from '@/mixins/parcel'
import septic from '@/mixins/septic'
import feature from '@/mixins/feature'
import utils from '../../mixins/utils'
import map from '../../mixins/map'
import { mapGetters, mapMutations } from 'vuex'
import { MglMap, MglMarker, MglScaleControl } from 'vue-mapbox'

import colors from '../../design/_colors.scss'
import MapSettings from './MapSettings.vue'
import MapCoordinates from './MapCoordinates.vue'
import DriveButtonToggle from '../../components/Drive/ButtonToggle.vue'
import Drive from '../../components/Drive/index.vue'
export default {
  mixins: [parcel, feature, septic, utils, map],
  props: {
    data: {},
    showClose: {
      type: Boolean,
      default: false
    }
  },
  components: {
    DeleteParcel,
    EditParcel,
    SepticSystemInformation,
    PropertyCardKey,
    WaterSourceInformation,
    HvacInformation,
    MglMap,
    MglMarker,
    ParcelGalleryDetail,
    ParcelGalleryButton,
    MapSettings,
    MglScaleControl,
    MapCoordinates,
    DriveButtonToggle,
    Drive
  },
  data: () => ({
    tooltip: false,
    taxCount: {},
    dialogs: {
      parcelMap: false,
      stat: false,
      snlMenu: false
    },
    mapDialogSize: 'ms',
    gallery: false,
    galleryCount: 0,
    drive: false,
    driveCount: 0
  }),
  computed: {
    parcelURL() {
      return `/parcels/${this.data.id}`
    },
    features() {
      return this.$store.getters.user.profile.features
    },
    componentType() {
      let res = null
      if (this.septicCount && this.septicCount.system.component_type) {
        res = this.getSepticComponent(this.septicCount.system.component_type)
      }
      return res
    },
    featureButtons() {
      return this.$store.getters.featuresList
    },
    colors() {
      return colors
    },
    mapKey() {
      return `${this.$store.state.parcel.mapConfig.style}`
    },
    computedParcelData() {
      let res = []

      //simple nitrogen load
      let snlData = {
        name: 'Simple Nitrogen Load',
        description: ``
      }

      let snl = this.calculateSnl(this.data)
      if (snl === null) {
        snlData.description = `Acres and Bedrooms must be specified first.`
      } else {
        snlData.description = `${snl}`
      }
      res.push(snlData)

      //building covereage
      if (
        this.isNumber(this.data.acres) &&
        this.isNumber(this.data.house_area)
      ) {
        let buildingCoverage =
          (100 * Number(this.data.house_area)) /
          (44000 * Number(this.data.acres))
        buildingCoverage = buildingCoverage.toFixed(1)
        res.push({
          name: 'Building Coverage',
          description: `${buildingCoverage} %`,
          buildingCoverage
        })
      }
      return res
    },
    profile() {
      return this.$store.getters.user.profile
    },
    showHouseLabel() {
      return this.parcels.some(parcel => parcel.house_area)
    },
    showBedBathLabel() {
      return this.parcels.some(parcel => parcel.bedroom || parcel.bathroom)
    },
    showNitrogenFootprint() {
      if (
        !this.data ||
        this.data.parcel_type === 'openSpace' ||
        this.data.parcel_type === 'lotWetland'
      ) {
        return false
      }
      return true
    },
    ...mapGetters(['parcels', 'scaleFilter'])
  },
  async mounted() {},
  methods: {
    geojson(nearby) {
      let res = []
      let gj = this.parcelPolygonGeojson(this.data)
      gj.properties.description = ''
      res.push(gj)
      if (nearby) {
        if (this.tctFilter) {
          this.tctParcels.map(e => {
            res.push(this.parcelPolygonGeojson(e))
          })
        }
        if (this.totFilter) {
          this.totParcels.map(e => {
            res.push(this.parcelPolygonGeojson(e))
          })
        }
      }
      return res
    },
    featureLink(feature) {
      return `${this.parcelURL}/${feature}`
    },
    stateTown(state, town) {
      let res = false
      if (this.data.state && this.data.townCity) {
        if (
          this.data.state.toLowerCase() == state.toLowerCase() &&
          this.data.townCity.toLowerCase() == town.toLowerCase()
        ) {
          return (res = true)
        }
      }
      return res
    },
    showAccessorMap() {
      let URL = 'https://www.mapsonline.net/truroma/?ts_cama_id='
      let parcelNumber = this.data.number
      URL += this.removePadding(parcelNumber)
      window.open(URL, '_blank')
    },
    showAccessorData() {
      let URL =
        'https://www.mapsonline.net/truroma/web_assessor/search.php#pid='
      let parcelNumber = this.data.number
      URL += this.removePadding(parcelNumber)
      window.open(URL, '_blank')
    },
    featureCount(f) {
      if (this.getFeatureInfo(f)) {
        return this.getFeatureInfo(f).count
      } else {
        return null
      }
    },
    findOrigFeatures(val) {
      let f = this.$store.getters.features.filter(e => e.value == val)
      if (f) {
        f = f[0]
      } else {
        f = {}
      }
      return f
    },
    septicSystemURL(id) {
      return `/parcels/${id}/septic?septic_card=true`
    },
    emitUpdatedData(data) {
      this.$emit('update', data)
      if (this.$route.query.view == 'list') {
        this.$refs.animation.animate()
      }
    },
    getFeatureInfo(feature) {
      if (this.data.metrics && this.data.metrics[feature]) {
        return this.data.metrics[feature]
      } else {
        return null
      }
    },
    getInformation(feature) {
      if (
        this.getFeatureInfo(feature) &&
        this.getFeatureInfo(feature).information
      ) {
        return this.getFeatureInfo(feature).information
      } else {
        return null
      }
    },
    onMapLoad({ map, hoverEffect, showTooltip, thumbnail }) {
      map = map.map

      let geojson = this.data.geojson
      if (!geojson) {
        return
      }

      try {
        geojson = JSON.parse(geojson)
      } catch (err) {
        console.log(err)
      }

      this.renderParcel(map, !thumbnail, true)

      if (hoverEffect) {
        this.addParcelHoverEffect(map, 'parcel-fill', 'parcel')
      }

      // if (showTooltip) {
      //   this.addParcelPopup(map, 'parcel-fill')
      // }

      if (!thumbnail && this.$vuetify.breakpoint.mdAndUp) {
        map.addControl(new this.$mapbox.NavigationControl())
      }

      if (this.data.bbox) {
        map.fitBounds(this.data.bbox)
      }
    },
    async renderParcel(map, detail, nearby = false) {
      this.removeLayer(map, 'parcel-fill')
      this.removeLayer(map, 'outline')
      this.removeLayer(map, 'label')
      this.removeSource(map, 'parcel')

      if (detail && nearby) {
        this.getNearbyParcels([{ number: this.data.number }]).then(() => {
          this.renderParcel(map, detail)
        })
      }

      this.addParcelSource(map, 'parcel', {
        data: {
          type: 'FeatureCollection',
          features: this.geojson(detail ? true : false)
        },
        type: 'geojson'
      })
      this.addParcelPolygon(map, 'parcel-fill', 'parcel')
      this.addParcelOutline(map, 'outline', 'parcel')
      if (detail) {
        this.addLabelLayer(map, 'label', 'parcel', true)
      }
    },
    resizeMap(map) {
      map.resize()
    },
    changeMapDialogSize() {
      if (this.mapDialogSize == 'ms') {
        this.mapDialogSize = 'ls'
      } else {
        this.mapDialogSize = 'ms'
      }
      setTimeout(() => {
        this.resizeMap(this.$refs.map.map)
      }, 500)
    },
    ...mapMutations(['latestParcel'])
  }
}
</script>

<style scoped lang="sass">
@import "../../design/_colors.scss"
.address
  color: #2a3331

.info-icon
  /* margin-left: -6px !important; */
  margin-top: 5px
  /* margin-right: 3px; */

.septic-f-column
  display: flex
  flex-direction: column
  display: inline-flex
  margin-right: 2px

.septic-features
  margin-top: -20px

.map-container
  .map-hint
    color: $color-light-black

  .map-clickable
    cursor: pointer

.map-card--title
  // font-size: 16px !important
  height: 50px
  margin-top: -13px

@media (max-width:600px)
  .map-card--title
    font-size: 14px !important

.mapboxgl-popup--opacity-60
  opacity: 0.6
.parcel-gb
  position: absolute
  right: 0
  margin: -23px 5px 0 0
</style>
