<template>
  <div>
    <v-dialog v-model="dialogs.agreement" max-width="400">
      <v-card class="mx-auto">
        <v-container class="pa-4">
          <p style="font-size: 1rem">
            I am the owner of this parcel and/or authorized to provide and
            review information about it.
          </p>
          <v-btn color="success" @click="agreeCert()"
            >Yes, Continue <v-icon>mdi-checkbox-blank-outline</v-icon></v-btn
          >
          <v-btn text color="secondary" @click="cancelCert()">Cancel</v-btn>
        </v-container>
      </v-card>
    </v-dialog>
    <slot name="head"></slot>
    <v-form v-model="valid" @submit.prevent="submit()" ref="form">
      <fieldset class="form-fieldset rounded-lg">
        <legend class="mx-auto px-3 title primary--text">Required</legend>
        <div class="pa-4">
          <v-row>
            <v-col cols="12" sm="3">
              <v-text-field
                autofocus
                :rules="rules.requiredRule"
                hint="Parcel ID can be found on your Real Estate Tax Bill"
                large
                label="Parcel ID"
                placeholder="Eg: 45-123"
                outlined
                v-model="formData.number"
                :error-messages="
                  disableCreate
                    ? 'This parcel already exists on your account'
                    : ''
                "
                @input="onParcelNumberChange"
              ></v-text-field>
            </v-col>
            <v-col cols="4" sm="3">
              <v-text-field
                :rules="rules.requiredRule"
                label="Street Number"
                outlined
                v-model="formData.street_number"
              ></v-text-field>
            </v-col>
            <v-col cols="8" sm="6">
              <v-text-field
                :rules="rules.requiredRule"
                label="Street Name"
                outlined
                v-model="formData.street_name"
              ></v-text-field>
            </v-col>
          </v-row>
          <!-- for small devices show zip and state on same row -->
          <v-row class="d-sm-none d-flex">
            <v-col>
              <v-text-field
                outlined
                v-model="formData.zip"
                :rules="rules.requiredRule"
                label="Zip"
                @input="processZip"
              ></v-text-field>
            </v-col>
            <v-fade-transition v-if="formData.zip || formData.state">
              <v-col>
                <v-select
                  outlined
                  label="State"
                  :items="states"
                  v-model="formData.state"
                  :rules="rules.requiredRule"
                ></v-select>
              </v-col>
            </v-fade-transition>
          </v-row>
          <v-row>
            <v-col class="d-sm-flex d-none" cols="12" sm="3" md="3">
              <v-text-field
                outlined
                v-model="formData.zip"
                :rules="rules.requiredRule"
                label="Zip"
                @input="processZip"
              ></v-text-field>
            </v-col>
            <v-fade-transition v-if="formData.zip || formData.townCity">
              <v-col cols="12" sm="6" md="6">
                <v-combobox
                  :items="townsCities"
                  v-model="formData.townCity"
                  outlined
                  label="Town/City"
                  :rules="rules.requiredRule"
                  @input="handleTownCity"
                  name="townCity"
                ></v-combobox>
              </v-col>
            </v-fade-transition>
            <v-fade-transition v-if="formData.zip || formData.state">
              <v-col class="d-sm-flex d-none" cols="12" sm="3" md="3">
                <v-select
                  outlined
                  label="State"
                  :items="states"
                  v-model="formData.state"
                  :rules="rules.requiredRule"
                ></v-select>
              </v-col>
            </v-fade-transition>
            <v-col cols="12" sm="4" md="4">
              <v-select
                outlined
                label="Parcel Type"
                :items="parcelTypes.data"
                item-text="text"
                v-model="formData.parcel_type"
                :rules="rules.requiredRule"
              ></v-select>
            </v-col>
            <v-col
              cols="12"
              sm="4"
              md="5"
              v-if="profile.includeParcelReference || (data && data.reference)"
            >
              <v-text-field
                outlined
                label="Reference"
                v-model="formData.reference"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="3">
              <v-text-field
                outlined
                v-model="formData.team"
                label="Team"
                v-if="profile.teams"
              ></v-text-field>
            </v-col>
          </v-row>
        </div>
        <div class="text-center show-optional">
          <v-btn
            text
            @click="toggleOptional()"
            class="text-none"
            color="primary"
          >
            {{ showOptional ? 'Hide' : 'Show' }} Optional Data
            <v-icon
              >{{ showOptional ? 'mdi-minus-circle' : 'mdi-plus-circle' }}
            </v-icon>
          </v-btn>
        </div>
      </fieldset>

      <v-expand-transition>
        <fieldset class="rounded-lg" v-if="showOptional">
          <legend class="mx-auto px-3 title primary--text">Optional</legend>
          <div class="pa-4">
            <v-row>
              <v-col cols="6" sm="3">
                <v-text-field
                  outlined
                  label="Acres"
                  type="number"
                  v-model="formData.acres"
                ></v-text-field>
              </v-col>
              <v-col cols="6" sm="3">
                <v-text-field
                  outlined
                  label="House SqFt"
                  type="number"
                  v-model="formData.house_area"
                ></v-text-field>
              </v-col>
              <v-col cols="6" sm="3">
                <v-text-field
                  outlined
                  type="number"
                  label="# Bedrooms"
                  v-model="formData.bedroom"
                ></v-text-field>
              </v-col>
              <v-col cols="6" sm="3">
                <v-text-field
                  outlined
                  type="number"
                  label="# Bathrooms"
                  v-model="formData.bathroom"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" sm="3">
                <v-text-field
                  autocomplete="state-class"
                  label="State Class"
                  outlined
                  v-model="formData.state_class"
                ></v-text-field>
              </v-col>

              <v-col v-if="formData.townCity == 'Truro'" cols="6" sm="3">
                <v-text-field
                  label="Property Card Key"
                  outlined
                  v-model="formData.key"
                ></v-text-field>
              </v-col>
              <v-col cols="6" sm="3">
                <v-checkbox
                  @change="changeWaterFront"
                  v-model="formData.waterfront"
                  label="Waterfront"
                ></v-checkbox>
              </v-col>
              <v-col cols="6" sm="3">
                <v-checkbox
                  @change="changeWaterView"
                  v-model="formData.waterview"
                  class="ml-7"
                  label="Waterview"
                ></v-checkbox>
              </v-col>
            </v-row>
            <div class="text-center">
              <real-estate-tax-bill :resize="false" text></real-estate-tax-bill>
            </div>
          </div>
        </fieldset>
      </v-expand-transition>

      <slot name="footer"></slot>

      <div class="text-center">
        <v-btn
          class="ml-2 mt-1"
          large
          color="primary"
          @click="showAgreement()"
          :loading="loadingAddParcel"
          :disabled="disableCreate"
          ><slot name="button">Submit</slot></v-btn
        >
        <span @click="cancel()" class="mt-1 ml-2">
          <cancel-button large></cancel-button>
        </span>
      </div>
    </v-form>
  </div>
</template>

<script>
import rules from '@/mixins/rules'
import RealEstateTaxBill from '@/components/RealEstateTaxBill'
import address from '@/mixins/address'
import parcel from '@/mixins/parcel'
export default {
  mixins: [rules, address, parcel],
  components: {
    RealEstateTaxBill
  },
  props: {
    data: {
      type: Object
    },
    clearForm: {
      type: Boolean,
      default: true
    },
    showOpt: {
      type: Boolean,
      default: false
    },
    disableCreate: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    valid: true,
    formData: {},
    optional: true,
    hideClicked: false,
    optionalFields: [
      'house_area',
      'bedroom',
      'bathroom',
      'acres',
      'state_class',
      'waterfront',
      'waterview',
      'key'
    ],
    dialogs: { agreement: false },
    origData: {}
  }),
  computed: {
    profile() {
      return this.$store.getters.user.profile
    },
    parcelTypes() {
      return this.$store.getters.parcelTypes
    },
    townsCities() {
      return this.$store.getters.townsCities
    },
    states() {
      return this.$store.getters.states
    },
    showOptional: {
      get() {
        let res = this.optional
        this.optionalFields.forEach(field => {
          if (this.formData[field] && !this.hideClicked) {
            return (res = true)
          }
        })
        return res
      },
      set(val) {
        this.optional = val
      }
    }
  },
  mounted() {
    this.setAddressData()
    if (this.showOpt) {
      this.showOptional = this.showOpt
    }
  },
  methods: {
    updateData() {
      if (this.data) {
        this.formData = { ...this.data }
        this.origData = this.data
      }
    },
    submit() {
      this.$refs.form.validate()
      if (this.valid) {
        this.$emit('submit', this.formData)
        if (this.clearForm) {
          this.$refs.form.reset()
        }
      }
    },
    handleTownCity(val) {
      if (val == 'Other') {
        this.formData.townCity = ''
      }
    },
    clearTownCity() {
      this.formData.townCity = ''
      this.$refs.form.resetValidation()
    },
    clearState() {
      this.formData.state = ''
      this.$refs.form.resetValidation()
    },
    clearAddress() {
      this.clearTownCity()
      this.clearState()
    },
    processZip(zip) {
      let address = this.addressForZip(zip)
      if (address) {
        if (address.townCity) {
          this.formData.townCity = address.townCity
        } else {
          this.clearTownCity()
        }
        if (address.state) {
          this.formData.state = address.state
        } else {
          this.clearState()
        }
      } else {
        this.clearAddress()
      }
    },
    changeWaterFront(val) {
      if (val) {
        this.formData.waterview = false
      }
    },
    changeWaterView(val) {
      if (val) {
        this.formData.waterfront = false
      }
    },
    showAgreement() {
      this.$refs.form.validate()
      if (this.valid) {
        if (this.data) {
          this.submit()
        } else {
          this.dialogs.agreement = true
        }
      }
    },
    agreeCert() {
      this.submit()
      this.parcelAuthCert(true)
    },
    cancelCert() {
      this.dialogs.agreement = false
      this.parcelAuthCert(false)
    },
    parcelAuthCert(res) {
      this.addParcelAuthCertification(res ? 'agree' : 'cancel')
      this.dialogs.agreement = false
    },
    cancel() {
      if (!this.data) {
        this.$refs.form.reset()
      } else {
        this.formData = JSON.parse(JSON.stringify(this.origData))
      }
      this.$emit('cancel')
    },
    toggleOptional() {
      this.hideClicked = true
      this.showOptional = !this.showOptional
    },
    onParcelNumberChange(num) {
      this.$emit('change:parcel', num)
      this.$store.commit('setDisableCreateParcel', false)
    }
  },
  watch: {
    data: {
      immediate: true,
      handler: 'updateData'
    }
  }
}
</script>

<style scoped>
.show-optional {
  margin-top: -10px !important;
}
</style>
