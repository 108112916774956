<template>
  <v-card max-width="500" class="map-settings">
    <v-card-title>
      <v-spacer></v-spacer>
      <v-btn @click="$emit('close')" icon>
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text class="mt-n3">
      <div v-if="profile.enableTeams && showFilter('teams')">
        <h5 class="text-h6">Filter by Teams</h5>
        <base-table-filter
          :items="teamsComputedMap"
          defaultColor=""
          flat
          :showTitle="false"
          v-model="teamFilter"
          :showClearButton="false"
        ></base-table-filter>
      </div>
      <div v-if="showFilter('parcelId')">
        <v-switch
          v-model="showParcelIdFilter"
          label="Show Parcel ID"
        ></v-switch>
      </div>
      <div v-if="showFilter('acres')">
        <v-switch v-model="acresFilter" label="Show Acres"></v-switch>
      </div>
      <div v-if="showFilter('scale')">
        <v-switch v-model="scaleFilter" label="Show Scale"></v-switch>
      </div>
      <div v-if="profile.includeParcelReference && showFilter('reference')">
        <v-switch v-model="referenceFilter" label="Show Reference"></v-switch>
      </div>
      <div v-if="profile.enableNearbyParcels">
        <div v-if="showFilter('nearbyTown')">
          <v-switch label="Show Nearby Town" v-model="totFilter"></v-switch>
        </div>
        <div v-if="showFilter()">
          <v-switch label="Show Nearby TCT" v-model="tctFilter"></v-switch>
        </div>
      </div>
      <div v-if="showFilter('style')">
        <h5 class="text-h6">Map Style</h5>
        <div>
          <v-chip
            :small="$vuetify.breakpoint.smAndDown"
            v-for="(style, index) in mapStyleList"
            :key="index"
            class="mx-1 my-1"
            @click="mapStyle = style.style"
            :outlined="style.style != mapStyle"
            :color="style.style == mapStyle ? 'primary' : ''"
          >
            {{ style.text }}
          </v-chip>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import parcels from '../../mixins/parcel'
import colors from '../../design/_colors.scss'
export default {
  mixins: [parcels],
  props: {
    show: {
      type: Array,
      default: () => {
        // teams, parcelId, acres, scale, reference, nearbyTown, nearbyTct, style
        return []
      }
    }
  },
  computed: {
    profile() {
      return this.$store.getters.user.profile
    },
    colors() {
      return colors
    }
  },
  methods: {
    showFilter(field) {
      if (this.show.length === 0) {
        return true
      }
      return this.show.includes(field)
    }
  }
}
</script>

<style lang="sass" scoped>
.map-settings
  opacity: 1
</style>
