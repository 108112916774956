<template>
  <div>
    <v-form
      ref="form"
      @submit.prevent="onCreate"
    >
      <v-text-field
        id="folder-name"
        v-model="formData.name"
        :rules="rules.folderNameRule"
      />
      <slot name="messages" />
      <div>
        <v-btn
          :loading="loading"
          class="mx-1 text-capitalize"
          color="primary"
          @click="onCreate"
        >
          Create
        </v-btn>
        <v-btn
          class="mx-1 text-capitalize"
          color="grey"
          text
          @click="onCancel"
        >
          Cancel
        </v-btn>
      </div>
    </v-form>
  </div>
</template>

<script>
const defaultFormData = () => ({
  name: 'Untitled folder',
});

export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      formData: defaultFormData(),
      valid: true,
      rules: {
        folderNameRule: [(v) => !!v || 'Folder name is required'],
      },
    };
  },
  mounted() {
    setTimeout(() => {
      const el = document.getElementById('folder-name');
      el.focus();
      el.select();
    }, 100);
  },
  methods: {
    resetForm() {
      this.formData = defaultFormData();
    },
    onCreate() {
      this.$refs.form.validate();
      if (!this.valid) {
        return;
      }
      this.$emit('submit', this.formData);
    },
    onCancel() {
      this.$emit('cancel');
      this.resetForm();
    },
  },
};
</script>

<style>
</style>
